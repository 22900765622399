import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentContainer } from "../components/Theme"
import { MemoryWrapper, MemoryContainer } from "../style/styled"

export default function MemoryPath3Page({ data }) {
  const allMemoryPath = data.allContentfulTheMemoryPathPart3Page.edges[0].node
  return (
    <Layout>
      <SEO title="The Memory Path Part 3 Page" />
      <ContentContainer>
        <h1>{allMemoryPath?.title}</h1>
        <MemoryWrapper>
          {allMemoryPath.entries.map(entry => {
            return (
              <Link
                key={entry.slug}
                to={`/${entry.slug}`}
                style={{ boxShadow: `none` }}
              >
                <MemoryContainer>
                  <h3>{entry.title}</h3>
                  <p>{entry.subtitle}</p>
                </MemoryContainer>
              </Link>
            )
          })}
        </MemoryWrapper>
      </ContentContainer>
    </Layout>
  )
}

export const memoryPathPageThreeQuery = graphql`
  query {
    allContentfulTheMemoryPathPart3Page {
      edges {
        node {
          title
          entries {
            slug
            subtitle
            title
          }
        }
      }
    }
  }
`
